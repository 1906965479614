<template>
  <div class="grid h-screen" style="grid-template-rows: 65px 1fr">
    <header class="grid" style="grid-template-columns: 96px 1fr 96px">
      <div class="p-1">
        <router-link to="/" class="inline-block w-12 h-12">
          <Icon name="flylist-logo.svg" />
        </router-link>
      </div>
      <h2 class="text-center h-full uppercase font-semibold py-4">{{sharedList ? sharedList.name : ''}}
        <div class="w-16 m-auto"><Icon name="flylist.svg" /></div>
      </h2>
      <div></div>
    </header>
    <main class="text-center">
      <div v-if="!sharedList || !sharedList.id" class="text-center py-10 text-pink-600">
        This flylist is no longer exists
      </div>
      <div v-else>
        <div class="m-auto relative bg-white py-4 px-2 sm:px-6 rounded-3xl sm:w-96 w-full shadow-xl">
          <label v-for="item in sharedList.items" :key="item.title" class="block relative p-4 m-auto cursor-pointer border-b border-pink-100 todo">
            <input class="todo__state absolute top-0 left-0 opacity-0" type="checkbox" v-model="item.done" @change="onChange(item)"/>
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200 25" class="todo__icon">
              <use xlink:href="#todo__line" class="todo__line"></use>
              <use xlink:href="#todo__box" class="todo__box"></use>
              <use xlink:href="#todo__check" class="todo__check"></use>
              <use xlink:href="#todo__circle" class="todo__circle"></use>
            </svg>
            <div class="todo__text text-left pl-8">{{item.quantity | quantity}} {{item.title}}</div>
          </label>
        </div>
        <svg viewBox="0 0 0 0" style="position: absolute; z-index: -1; opacity: 0;">
          <defs>
            <linearGradient id="boxGradient" gradientUnits="userSpaceOnUse" x1="0" y1="0" x2="25" y2="25">
              <stop offset="0%"   stop-color="#27FDC7"/>
              <stop offset="100%" stop-color="#0FC0F5"/>
            </linearGradient>

            <linearGradient id="lineGradient">
              <stop offset="0%"    stop-color="#0FC0F5"/>
              <stop offset="100%"  stop-color="#27FDC7"/>
            </linearGradient>

            <path id="todo__line" stroke="url(#lineGradient)" d="M21 12.3h168v0.1z"></path>
            <path id="todo__box" stroke="url(#boxGradient)" d="M21 12.7v5c0 1.3-1 2.3-2.3 2.3H8.3C7 20 6 19 6 17.7V7.3C6 6 7 5 8.3 5h10.4C20 5 21 6 21 7.3v5.4"></path>
            <path id="todo__check" stroke="url(#boxGradient)" d="M10 13l2 2 5-5"></path>
            <circle id="todo__circle" cx="13.5" cy="12.5" r="10"></circle>
          </defs>
        </svg>
        <footer class="py-6 opacity-30">
          <p class='text-sm m-auto w-80 font-light'>{{ sharedList.sharedAt | toDate }}</p>
          <div class="w-20 m-auto mt-4">
            <router-link to="/" class="inline-block w-full">
              <Icon name="flylist.svg" />
            </router-link>
          </div>
        </footer>
      </div>
    </main>
  </div>
</template>

<script>
import { format, formatDistanceToNow } from 'date-fns'
import Icon from '@/components/Icon'
import { mapState } from 'vuex'

export default {
  name: 'Share',
  computed: {
    ...mapState(['sharedList']),
  },
  components: {
    Icon
  },
  methods: {
    onChange(item) {
      this.$store.dispatch('updateSharedListItem', item)
    }
  },
  filters: {
    quantity (value) {
      if (!value || value === 1) return ''
      value = value.toString()
      return value + ' x '
    },
    toDate: function (value) {
      if (!value) return ''
      const date = value.toDate()
      return format(date, "'Received on' PPPPpppp") + ` | ${formatDistanceToNow(date)} ago`
    }
  },
  mounted () {
    this.$store.dispatch('fetchSharedList', this.$route.params.id)
  }
}
</script>

<style scoped>
.todo:last-child {
  border-bottom: none;
}
.todo__text {
  color: #135156;
  transition: all 0.4s linear 0.4s;
}
.todo__icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin: auto;
  fill: none;
  stroke: #27FDC7;
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}
.todo__line,
.todo__box,
.todo__check {
  transition: stroke-dashoffset 0.8s cubic-bezier(0.9, 0, 0.5, 1);
}
.todo__circle {
  stroke: #27FDC7;
  stroke-dasharray: 1 6;
  stroke-width: 0;
  transform-origin: 13.5px 12.5px;
  transform: scale(0.4) rotate(0deg);
  -webkit-animation: none 0.8s linear;
          animation: none 0.8s linear;
}
@-webkit-keyframes explode {
  30% {
    stroke-width: 3;
    stroke-opacity: 1;
    transform: scale(0.8) rotate(40deg);
  }
  100% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1.1) rotate(60deg);
  }
}
@keyframes explode {
  30% {
    stroke-width: 3;
    stroke-opacity: 1;
    transform: scale(0.8) rotate(40deg);
  }
  100% {
    stroke-width: 0;
    stroke-opacity: 0;
    transform: scale(1.1) rotate(60deg);
  }
}
.todo__box {
  stroke-dasharray: 56.1053, 56.1053;
  stroke-dashoffset: 0;
  transition-delay: 0.16s;
}
.todo__check {
  stroke: #27FDC7;
  stroke-dasharray: 9.8995, 9.8995;
  stroke-dashoffset: 9.8995;
  transition-duration: 0.32s;
}
.todo__line {
  stroke-dasharray: 168, 1684;
  stroke-dashoffset: 168;
}
.todo__circle {
  -webkit-animation-delay: 0.56s;
          animation-delay: 0.56s;
  -webkit-animation-duration: 0.56s;
          animation-duration: 0.56s;
}
.todo__state:checked ~ .todo__text {
  transition-delay: 0s;
  color: #5EBEC1;
  opacity: 0.6;
}
.todo__state:checked ~ .todo__icon .todo__box {
  stroke-dashoffset: 56.1053;
  transition-delay: 0s;
}
.todo__state:checked ~ .todo__icon .todo__line {
  stroke-dashoffset: -8;
}
.todo__state:checked ~ .todo__icon .todo__check {
  stroke-dashoffset: 0;
  transition-delay: 0.48s;
}
.todo__state:checked ~ .todo__icon .todo__circle {
  -webkit-animation-name: explode;
          animation-name: explode;
}
</style>